import Toast from 'react-bootstrap/Toast';
import '../../Assets/css/StyleListado.css'
import React, { useState, useEffect  } from 'react';
import '../../Assets/css/din_style_3.css'
import '../../Assets/css/fontsize.css'
import { useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";

function ListadoView(props) {
    const { url, tabla} = props; 
    //Redux
    //Redux
    const user = useSelector((state) => state.user);
    const [Idempresa, setIdempresa] = useState(user.companyId);
    const [Idusuario, setIdusuario] = useState(user.userId);
    useEffect(() => {
        setIdempresa(user.companyId);
        setIdusuario(user.userId);
    }, [user]);
    const [loading, setLoading]=useState(false);
    const [data, setData]=useState([]);
    const [form]=useState({estado:'', page:0, buscar:true}); // form es la variable formulario de estado que tiene los datos
    useEffect(()=>{
        if(form.buscar){
            searchData();
            const intervalId = setInterval(() => {
                searchData();
            }, 30000); // 10000 ms = 30 segundos
            return () => clearInterval(intervalId);
        }
        // eslint-disable-next-line
    },[form,user.companyId]);
    const searchData= async ()=>{
        //setLoading(true);
        try {
            const token = localStorage.getItem('token-dincors');
            const jsonData = {
                idempresa:user.companyId,
            };
            //console.log(jsonData)
            const response = await fetch(`${url}/${tabla}/${tabla}List`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${token}`
                },
                body:JSON.stringify(jsonData)
            });
            if(response.ok){
                const data = await response.json();
                //console.log(data)
                if(data.res){
                    setData(data.datos);
                }else{
                    throw new Error(data.msg);
                }
            }else{
                throw new Error(`${response.status} ${response.statusText}`);
            }
        }catch (error) {
            setToastBackground('#CC6868');
            setToastMessage(error.message);
            setShowToast(true);
        }finally{
            setLoading(false);
        }
    }
    // Obtener detalles
    const [Idhabitacion, setIdhabitacion] = useState('');             // <<<<<< CAMBIO
    const [Idestado, setIdestado] = useState('');             // <<<<<< CAMBIO
    const [Idalquiler, setIdalquiler] = useState('');             // <<<<<< CAMBIO
    const [ShowDetalleHabitacion, setShowDetalleHabitacion] = useState(false);
    const CloseDetalleHabitacion = () => setShowDetalleHabitacion(false);
    const [DataHabitacion, setDataHabitacion] = useState([]);
    const [DataAlquiler, setDataAlquiler] = useState([]);
    const FnDetailAlquiler = async(idhabitacion,idestado,idalquiler) => {
        setIdhabitacion(idhabitacion);
        setIdestado(idestado);
        setIdalquiler(idalquiler);
        const token = localStorage.getItem('token-dincors');
        const jsonData = {
            Idhabitacion: idhabitacion,
            Idalquiler: idalquiler,
        };
        try {
            const response = await fetch(`${url}/Alquileres/AlquileresDetail`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(jsonData),
            });
            if(response.ok){
                const data = await response.json();
                console.log("datos: ",data)
                if(data.res){
                    setShowDetalleHabitacion(true);
                    setDataHabitacion(data.habitacion);
                    setDataAlquiler(data.datos);
                }else{
                    throw new Error(data.msg);
                }
            }else{
                throw new Error(`${response.status} ${response.statusText}`);
            }
        }catch (error) {
            setToastBackground('#CC6868');
            setToastMessage(error.message);
            setShowToast(true);
        }
    };
    // Pasar de Mantenimiento a disponible
    const [ShowEstadoHabitacion, setShowEstadoHabitacion] = useState(false);
    const handleCloseEstadoHabitacion = () => setShowEstadoHabitacion(false);
    const handleShowEstadoHabitacion = (idhabitacion,idestado,idalquiler) => {
        setShowEstadoHabitacion(true);
        setIdhabitacion(idhabitacion);
        setIdestado(idestado);
        setIdalquiler(idalquiler);
    };
    // Cierre caja 
    const FnEstadoHabitacion = async () => {
        const jsonData = {
            Idhabitacion:Idhabitacion,
            Idempresa: Idempresa,
            Idusuario: Idusuario,
        };
        const token = localStorage.getItem('token-dincors');
        try{
            const response = await fetch(`${url}/Alquileres/AlquileresEstadoHabitacion`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(jsonData),
            })
            if(response.ok){
                const data = await response.json();
                if(data.res){
                    setData(data.recepcion);
                    setToastBackground('#198754');
                    setToastMessage(data.msg);
                    setShowToast(true);
                }else{
                    throw new Error(data.msg);
                }
            }else {
                throw new Error(`${response.status} ${response.statusText}`);
            }
        } catch(error) {
            setToastBackground('#CC6868');
            setToastMessage(error.message);
            setShowToast(true);
        } finally {
            handleCloseEstadoHabitacion();
        };
    };
    // Redireccionar con navigate
    const navigate = useNavigate();
    const handleRedirect = async(a,b,c) => {
        if (b === 1 && c === null){
        navigate(`/${tabla}Add/${a}`)
        }else if(b === 2 && c !== null){
        navigate(`/${tabla}Upd/${a}/${c}`)
        }
    };
    // ====================== TOAST ======================
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastBackground , setToastBackground ] = useState('#198754');
    return (
        <section className="container-fluid mt-3">
        <div className="card m-1">
            <h5 className="card-header din-card-header">LISTADO</h5>
            <div className="card-body">
            {loading ? (
                    <div className='w-100' style={{ height: '700px', display: 'flex', alignItems: 'center', justifyContent: 'center',color:'#198754'}}>
                    <i className="fa-solid fa-spinner fa-spin-pulse fa-lg" style={{fontSize:'60px'}}></i>
                    &nbsp;&nbsp;<h1>Cargando datos...</h1>
                    </div>
            ) : (
                <div style={{ width: '100%' }}>
                <div className="row p-1">
                    <div className='col-12'>
                    <div className="table" style={{ marginTop: '5px'}} id="table_id">
                        <div className='tbody mx-2' >
                        <div className='container-fluid'>
                            <div className='row '>
                            {
                                data.length > 0 ? (
                                data.map((item, index) => (
                                    <div className='col-6 col-sm-4 col-md-3 col-lg-2 mb-3' key={index}>
                                        <div className={`card mb-0`} style={{width:'100%'}}>
                                            <div className={`card-header text-center px-0 py-0 ${item.alerta === 1 ? 'parpadeo' : ''}`} style={{background:`${{1: '#28a745',2: '#dc3545',3: '#17a2b8',}[item.idestado]}` }}>
                                                {item.idestado===3 ?
                                                    <Link onClick={() => handleShowEstadoHabitacion(item.idhabitacion,item.idestado,item.idalquiler)} style={{textDecoration:'none', cursor: 'pointer'}} className="text-white">
                                                        <div className="texto-mediano">{item.estnom}</div>
                                                    </Link>
                                                    :
                                                    <Link onClick={() => FnDetailAlquiler(item.idhabitacion,item.idestado,item.idalquiler)} style={{textDecoration:'none', cursor: 'pointer'}} className="text-white">
                                                        <div className="texto-mediano">{item.estnom}</div>
                                                    </Link>
                                                }
                                            </div>
                                            <div className="card-body text-center py-2 px-0">
                                                <a href onClick={() => handleRedirect(item.idhabitacion,item.idestado,item.idalquiler)} style={{textDecoration:'none', cursor: 'pointer'}}>
                                                    <div className="texto-grande text-black" style={{fontWeight:'500'}}>
                                                        {item.numero}&nbsp;
                                                        {/* {item.idestado === 2 && item.alerta === 1 ? (
                                                        <i className="text-warning fa-solid fa-bell fa-bounce fa-xs"></i>
                                                        ):null} */}
                                                    </div>
                                                </a>
                                                <div className='texto-small mx-2 d-flex justify-content-center align-items-center' style={{height:'32px'}}>{item.categoria}</div>
                                            </div>
                                        </div>
                                    </div>
                                    ))
                                ) : (
                                <div>
                                <div className='text-center'>No hay datos disponibles</div>
                                </div>
                                )
                            }
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <br/>
                </div>
            )}
            {/* Toast */}
            <Toast show={showToast} onClose={() => setShowToast(false)} delay={4000} autohide className='text-white'
                style={{ position: 'fixed',top: '20px',right: '20px',zIndex: 1100,background: `${toastBackground}` }}>
                <Toast.Header>
                    <strong className="me-auto">Notificación</strong>
                    <small></small>
                </Toast.Header>
                <Toast.Body>{toastMessage}</Toast.Body>
            </Toast>
            </div>
        </div>
        {/* Modal Detalle Habitacion */}
        <>
            <Modal show={ShowDetalleHabitacion} onHide={CloseDetalleHabitacion} size="lg">
                <Modal.Header closeButton style={{backgroundColor:'#198754', color:'White'}}>
                    <Modal.Title>Habitacion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 ">
                            <div className="card">
                                <h5 className="card-header din-card-header">DATOS DE HABITACION</h5>
                                <div className="card-body ">
                                    {/* DETALLE */}
                                    <div className="row">
                                        <div className="col-12">
                                                <div className="row px-4">
                                                    <div className='col-12 col-sm-12 col-md-6'>
                                                        <div className='row'>
                                                            {DataAlquiler && (
                                                            <div className={`col-12 col-sm-12 col-md-12 mb-1`}>
                                                                <label className="form-label fw-bold">Numero de Alquiler:&nbsp;&nbsp;</label>{DataAlquiler.alqcod}
                                                            </div>
                                                            )}
                                                            <div className={`col-12 col-sm-12 col-md-12 mb-1`}>
                                                                <label className="form-label fw-bold">Numero de Habitacion:&nbsp;&nbsp;</label>{DataHabitacion.numero}
                                                            </div>
                                                            <div className={`col-12 col-sm-12 col-md-12 mb-1`}>
                                                                <label className="form-label fw-bold">Tipo de Habitacion:&nbsp;&nbsp;</label>{DataHabitacion.categoria}
                                                            </div>
                                                            <div className={`col-12 col-sm-12 col-md-12 mb-1`}>
                                                                <label className="form-label fw-bold">Estado de Habitacion:&nbsp;&nbsp;</label>
                                                                <div className={`texto-small badge ${{1: 'bg-success',2: 'bg-danger',3: 'bg-warning',}[DataHabitacion.idestado]}`}>
                                                                    {DataHabitacion.estado}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 col-sm-12 col-md-6'>
                                                        <div className='row'>
                                                            <div className={`col-12 col-sm-12 col-md-12 mb-1`}>
                                                                <label className="form-label fw-bold">Nivel:&nbsp;&nbsp;</label>{DataHabitacion.nivel}
                                                            </div>
                                                            <div className={`col-12 col-sm-12 col-md-12 mb-1`}>
                                                                <label className="form-label fw-bold">Detalle:&nbsp;&nbsp;</label>{DataHabitacion.detalle}
                                                            </div>
                                                            <div className={`col-12 col-sm-12 col-md-12 mb-1`}>
                                                                <label className="form-label fw-bold">Personas permitidas:&nbsp;&nbsp;</label>{DataHabitacion.cantidad_personas}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>     
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12 col-sm-12 col-md-12 ">
                            <div className="card">
                                <h5 className="card-header din-card-header">DATOS DE ALQUILER</h5>
                                <div className="card-body ">
                                    <div className="row">
                                        <div className="col-12">
                                        {DataAlquiler ? (
                                            <div className="row px-4">
                                                <div className='col-12 col-sm-12 col-md-6'>
                                                    <div className='row'>
                                                        <div className={`col-12 col-sm-12 col-md-12 mb-1`}>
                                                            <label className="form-label fw-bold">Cliente:&nbsp;&nbsp;</label>{DataAlquiler.huesped_nombre}
                                                        </div>
                                                        <div className={`col-12 col-sm-12 col-md-12 mb-1`}>
                                                            <label className="form-label fw-bold">Tipo documento:&nbsp;&nbsp;</label>{DataAlquiler.txttipodocumento}
                                                        </div>
                                                        <div className={`col-12 col-sm-12 col-md-12 mb-1`}>
                                                            <label className="form-label fw-bold">Numero documento:&nbsp;&nbsp;</label>{DataAlquiler.huesped_documento}
                                                        </div>
                                                        <div className={`col-12 col-sm-12 col-md-12 mb-1`}>
                                                            <label className="form-label fw-bold">Direccion:&nbsp;&nbsp;</label>{DataAlquiler.huesped_direccion}
                                                        </div>
                                                        <div className={`col-12 col-sm-12 col-md-12 mb-1`}>
                                                            <label className="form-label fw-bold">Fin de alquiler:&nbsp;&nbsp;</label>{DataAlquiler.aloj_checkout_fecha} {DataAlquiler.aloj_checkout_hora}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-12 col-sm-12 col-md-6'>
                                                    <div className='row'>
                                                        <div className='d-flex justify-content-center mt-2 mb-2'>
                                                            <table className='mx-5 w-100'>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="p-0 text-center w-50" >PAGADO</td>
                                                                        <td className="p-0 text-center w-50">TOTAL</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="p-2 text-center texto-xl w-50 text-success" style={{borderRight:'1px solid black', fontWeight:'500'}}>{(parseFloat(DataAlquiler.aloj_monto_total - DataAlquiler.aloj_monto_cobrar).toFixed(2))}</td>
                                                                        <td className="p-2 text-center texto-xl w-50" style={{fontWeight:'500'}}>{(parseFloat(DataAlquiler.aloj_monto_total ? DataAlquiler.aloj_monto_total : 0.00).toFixed(2))}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="p-2 w-50 text-end">COBRAR</td>
                                                                        <td className="p-2 w-50 text-start texto-grande text-danger" style={{fontWeight:'500'}}>{parseFloat(DataAlquiler.aloj_monto_cobrar ? DataAlquiler.aloj_monto_cobrar:0.00).toFixed(2)}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>   
                                        )
                                        : (
                                            <div className='row px-4'> 
                                                <span className='text-center texto-xl text-secondary' style={{opacity:'0.3'}}> 
                                                    HABITACION DISPONIBLE
                                                </span>
                                            </div>
                                        )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={() => handleRedirect(Idhabitacion,Idestado,Idalquiler)}>Ingresar</Button>
                    <Button variant="secondary" onClick={CloseDetalleHabitacion}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        </>
        {/* Modal Modificar Estado*/}
        <Modal show={ShowEstadoHabitacion} onHide={handleCloseEstadoHabitacion}>
            <Modal.Header  className='bg-success text-white' closeButton>
              <Modal.Title>Estado de Habitacion</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Confirmar para establecer la disponibilidad de la habitacion
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-secondary din-btn-style" onClick={handleCloseEstadoHabitacion}>
                <i className="fa-solid fa-xmark"></i>&nbsp;&nbsp;Cancelar
              </button>
              <button className="btn btn-success din-btn-style" onClick={FnEstadoHabitacion}>
                <i className="fa-solid fa-check"></i>&nbsp;&nbsp;Confirmar
              </button>
            </Modal.Footer>
        </Modal>
        </section>
    );
}

export {ListadoView};