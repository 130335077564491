import { useState, useEffect } from "react";

function InputComboBoxBusquedaAlojamientoAdicional(props) {
    const [ListarDatos, setListaComboBox] = useState([]);
    useEffect(() => {
        const data = [
            { id: '3', nombre: 'CHECKOUT TARDIO'},
            // { id: '4', nombre: '04 DIA ADICIONAL'},
        ];
        setListaComboBox(data);
    },[]);
    const handleSelectChange = (e) => {
        const IdValue = e.target.value;
        const dataValue = ListarDatos.find((dato) => dato.id === IdValue);
        props.onChange(IdValue, dataValue ? dataValue.nombre : "");
    };
    return (
        <div className={`col-12 col-sm-${props.colsm} col-md-${props.colmd} mb-3`}>
            {/* <label htmlFor={`cbx${props.name}`} className="form-label">{props.campo}<span className="text-danger">{props.obligatorio}</span></label> */}
            <select className="form-select" id={`cbx${props.name}`} onChange={handleSelectChange} value={props.value || ""}>
                {/* <option value="">- {props.campo} -</option> */}
                {ListarDatos.map((dato) => (
                    <option key={dato.id} value={dato.id}>
                        {dato.nombre}
                    </option>
                ))}
            </select>
        </div>
    );
}

export {InputComboBoxBusquedaAlojamientoAdicional};